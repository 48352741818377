export default function TournamentsPage() {
    return <div>
        <section className="d-flex align-items-center">
            <div className="container bg-fancy card">
                <h2 className="text-center">Tournament Rules</h2>

                <div>
                    <h2>Monthly Tournament</h2>

                    <h4>Time and entry:</h4>
                    <ul>
                        <li>The tourney is happening on the 4<sup>th</sup> Saturday each Month at 15:00 UTC</li>
                        <li>Day 2 will start one day after Day 1 at 15:00 UTC</li>
                        <li>Be available 10 minutes before the tourney starts</li>
                        <li>Entry requirement: <i>4500 of peak normal sync mmr <b>OR</b> 5000 peak async mmr</i> (you can check your peak on the website https://fairytalefables.com after logging in)</li>
                        <li>The peak mmr will be evaluated when you click "Enter" in the tourney client in-game.</li>
                        <li>Communication will happen over Discord in #tourney-announcements and #tournament. Organization will run in the in-game tourney client (Title screen button "Tournaments"). If for some reason it does not work, organization will be over Discord.</li>
                    </ul>

                    <h4>Day 1:</h4>
                    <ul>
                        <li>If you plan to play, please react to <u><a href="https://discord.com/channels/1159819681180422194/1183099841027518484/1231616377694064725" target="_blank">this message on Discord</a></u> to get the correct role</li>
                        <li>Fixed number of rounds depending on the number of participants (provisional: <b>5</b> rounds), in
                            which players will be assigned swiss style into lobbies with players that have similar points. To not have all top 8 fight against each other, the matchmaking will be "spread" to take each 2nd placement so in the current ranking the places "1,3,5,7,9,11,13,15" and "2,4,6,8,10,12,14,16" will fight against each other (the same for lower placements, so places "17,19..." and "18,20,..." are put in lobbies). Non-full lobbies (only possible at the bottom of the rankings) will be filled with saved boards of the current patch of &gt;5200 mmr. Where you place will be what points you get (no adjustment for saved boards).</li>
                        <li>There will be a 60 second break in-between each round, and a 10 minute break after the 3rd game.</li>
                        <li>Each round will give points being 0, 1, 2, 3, 5, 7, 8, 10 for the placements 8th, 7th, ... 1st</li>
                        <li><i>No streaming delay required</i>, since it is now possible to see opponent hands if they have hand summons.</li>
                    </ul>

                    Point ties will be broken by:
                    <ul>
                        <li>highest placement</li>
                        <li>highest placement in shared lobbies</li>
                        <li>peak normal sync mmr of this season</li>
                        <li>random</li>
                    </ul>

                    <h4>Day 2:</h4>
                    <ul>
                        <li>There is an official cast on the <u><a href="https://www.twitch.tv/fairytalefables" target="_blank">official FTF twitch account</a></u></li>
                        <li>Players must stream the game over Discord.</li>
                        <li>Best (available) 8 players from day 1 (based on points, tie-breakers). If any top 8 player of Day 1 does not show up or drops out for any reason, the next person of Day 1 has 5 minutes time to react to a ping on discord. If the next person is ready to jump in, they will take over for the player (the original player cannot win any prize) with the current points.</li>
                        <li>Players start with 0 points. Each round will give points being 0, 1, 2, 3, 5, 7, 8, 10 for the placements 8th, 7th, ... 1st (same as day 1)</li>
                        <li>Race format: Players compete in lobbies to first gain <b>19 points</b>, after which they are called "live". Live players that place 1st win the tourney, at which point the tourney is over.</li>
                        <li>The 2nd and 3rd place are then evaluated on max points. In case of a point tie the prize of 2nd and 3rd place is summed up and equally split.</li>
                        <li>Cash prizes will be payed over PayPal and recipients will have to sign a confirmation of payment.</li>
                        <li>Organization of Day 2 will happen over Discord (not the tourney client) to be more flexible.</li>
                    </ul>

                    <h4>Rewards</h4>
                    <ul>
                        <li>1st place: the monthly tournament winner crown border exclusive to monthly tournament first place +30k crystals</li>
                        <li>2nd place: 20k crystals</li>
                        <li>3rd place: 10k crystals</li>
                        <li>The top 3 players of day 2: 1 progress point added to the 'Medalist' achievement</li>
                        <li>The top 8 players of day 1: 1 progress point added to the 'Finalist' achievement and the battle pass for 1 month</li>
                        <li>All players participating that placed 2nd or higher in at least 1 game: 4k crystals</li>
                        <li>All players participating that placed 4th or higher in at least 1 game: 2k crystals</li>
                        <li>All players participating that placed 6th or higher in at least 1 game: 2k crystals</li>
                        <li>There will be a code for 4k crystals for watching the tourney, only redeemable during the stream.</li>
                    </ul>

                    <h4>Other rules:</h4>
                    <ul>
                        <li>You must be on the latest patch when participating in the tourney</li>
                        <li>You must be able to stream your game on discord/ZOOM on day 2</li>
                        <li>Lobbies will not be re-made if any bugs happen.</li>
                    </ul>
                </div>
            </div>
        </section>
    </div>;
}